import React, { FC } from 'react';

import WorkPageTemplate from '../../atomic/templates/WorkPageTemplate';

const pageData = {
  header: {
    title: 'Invigorade',
    useCase: 'PACKAGING, WEB DESIGN, WEB DEVELOPMENT, VIDEO PRODUCTION',
    description: `Invigorade was a full circle brand development. I was tasked to have me and my team re-think the original Invigorade branding and packaging. With a market full of energy drinks we wanted to position the brand as a before, during and after support drink. Not just to fuel the workout or competition but to also help the athlete recover and feel the recovery.

    We went with an aggressive brand approach, capturing motion, energy, heat in the consumers activities. The brand colors played a larger roll in all aspects of the branding and design. The goal was to have the color be recognizable quickly regardless of how it was being used.`,
    comment: 'company acquired',
  },
  caseId: 'invigorade',
  contents: [
    'image0.jpg',
    'image1.jpg',
    'image2.jpg',
    'image3.jpg',
    'image4.jpg',
    {
      jwplayer: {
        videoSrc: 'https://content.jwplatform.com/videos/yQC63HIE-giz7EkeH.mp4',
        imageSrc: 'https://content.jwplatform.com/thumbs/yQC63HIE-1280.jpg',
      },
    },
    'image6.jpg',
    'image7.jpg',
    'image8.jpg',
    'image9.jpg',
    'image10.jpg',
    'image11.jpg',
    'image12.jpg',
    'image13.jpg',
  ],
};

export default (() => <WorkPageTemplate data={pageData} />) as FC;
